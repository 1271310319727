import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

/**
 * Web hook to query deals channel stats.
 * @param params
 */
const useDealChannelStats = (params = {}) => {
  const { siteId } = useActiveSite();

  return api.useQuery(['/revops/deal-channel-stats', { query: { siteId, ...params } }], {
    enabled: !!siteId,
    select: (data) => data.data ?? data ?? [],
    placeholderData: [],
  });
};

export default useDealChannelStats;
