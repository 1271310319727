import { castArray, cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

export const todoQueryKeys = {
  projectTodolists: ({ projectId, todolistId, queryParams = {}, todosCount = false } = {}) => {
    if (!projectId && !todolistId) {
      return null;
    }
    if (todolistId) {
      return [
        'tw/todolists',
        todolistId,
        {
          $join: ['milestone', 'availableFields', ...(todosCount ? ['todosCount'] : [])],
          ...queryParams,
        },
      ];
    }
    return [
      'tw/todolists',
      'find',
      {
        $sort: { position: 1, todolistId: 1 },
        projectId,
        completed: 0,
        $limit: -1,
        $join: ['milestone', 'availableFields', ...(todosCount ? ['todosCount'] : [])],
        ...queryParams,
      },
    ];
  },
  projectTodos: ({ projectId, todolistId, queryParams = {}, includeCustomFields = false } = {}) => {
    if (!projectId && !todolistId) {
      return null;
    }
    return [
      'tw/todos',
      'find',
      {
        ...(projectId && { projectId }),
        ...(todolistId && { todolistId }),
        completedAt: null,
        $sort: { position: 1 },
        $join: includeCustomFields ? ['commentsCountRead', 'customFields'] : ['commentsCountRead'],
        $limit: -1,
        ...queryParams,
      },
    ];
  },
  allProjectTodos: ({
    projectId = null,
    todolistIds,
    queryParams = {},
    includeCustomFields = false,
  } = {}) => {
    if (!projectId && !todolistIds) {
      return null;
    }
    return [
      'tw/todos',
      'find',
      {
        completedAt: null,
        projectId,
        todolistCompleted: 0,
        todolistDeletedAt: null,
        ...(todolistIds && todolistIds.length > 0 ? { todolistId: { $in: todolistIds } } : {}),
        $join: [
          'todolist',
          'responsibleUser',
          'responsibleCompany',
          'commentsCountRead',
          ...(includeCustomFields ? ['customFields'] : []),
          'creator',
        ],
        $sort: {
          createdAt: -1,
          dueDate: -1,
          content: 1,
        },
        $limit: -1,
        $client: { for: 'stripHtml' },
        $derivedColumns: true,
        ...queryParams,
      },
    ];
  },
  completedTodos: ({
    projectId,
    todolistId,
    queryParams = {},
    includeCustomFields = false,
  } = {}) => {
    if (!projectId && !todolistId) {
      return null;
    }
    return [
      'tw/todos',
      'find',
      {
        todolistId,
        completedAt: { $ne: null },
        $sort: { completedAt: -1 },
        $join: includeCustomFields ? ['commentsCountRead', 'customFields'] : ['commentsCountRead'],
        $limit: 50,
        ...queryParams,
      },
    ];
  },
  bulkCompletedTodos: ({ projectId, todolistId, queryParams = {} } = {}) => {
    return [
      'tw/bulk-completed-todos',
      'find',
      {
        ...(projectId && { projectId }),
        ...(todolistId && { todolistId }),
        $limit: 3,
        ...queryParams,
      },
    ];
  },
  singleTodo: ({ todoId, queryParams, $joins } = {}) => {
    if (!todoId) {
      return null;
    }
    return [
      'tw/todos',
      todoId,
      {
        $ignoreDeletedAt: true,
        $join: {
          todolist: true,
          subscribers: {
            $join: ['user'],
          },
          deleter: true,
          ...$joins,
        },
        ...queryParams,
      },
    ];
  },
};

/**
 * Update todo data
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.queryKey
 * @param {object|Array} args.todoData
 * @param {boolean} args.isListsPlus
 */
export const replaceInTodolist = ({ queryClient, queryKey, todoData }) => {
  const updatedTodoData = castArray(todoData);
  if (!updatedTodoData.length) {
    return;
  }

  for (const updateData of updatedTodoData) {
    const cache = queryClient.getQueryData(queryKey);
    if (!cache) {
      continue;
    }
    const todoId = updateData.todoId;
    const [todo, index] = getTodoFromCache({ todoId, cache });
    if (todo === null || index === null) {
      continue;
    }
    if (updateData.customFields && Array.isArray(updateData.customFields)) {
      updateData.customFields.forEach((element) => {
        if (todo.customFields[element.fieldId]) {
          todo.customFields[element.fieldId].value = element.value;
        } else {
          todo.customFields[element.fieldId] = { fieldId: element.fieldId, value: element.value };
        }
      });
      delete updateData.customFields;
    }

    const updatedTodo = { ...todo, ...updateData };
    cache[index] = updatedTodo;
    queryClient.setQueryData(queryKey, cache);
  }
};

/**
 * Add a todo to a todolist
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.queryKey
 * @param {number} args.todolistId
 * @param {object|Array} args.todos
 * @param {number} args.atIndex
 */
export const addToTodolist = ({ queryClient, queryKey, todolistId, todos, atIndex = null }) => {
  const cache = queryClient.getQueryData(queryKey);
  if (!cache) {
    return;
  }

  const todosToAdd = castArray(todos).map((todo) => ({ ...todo, todolistId }));
  if (!todolistId || !todosToAdd.length) {
    return;
  }

  const todolistTodos = getTodolistTodosFromCache({ todolistId, cache, clone: true });
  const index = atIndex ?? todolistTodos?.length;

  const updatedCache = cache.filter((todo) => todo.todolistId !== todolistId);
  todolistTodos.splice(index, 0, ...todosToAdd);
  updatedCache.push(...todolistTodos);

  queryClient.setQueryData(queryKey, updatedCache);
};

/**
 * Remove a todo from a todolist
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.projectId
 * @param {object} args.todolistId
 * @param {number} args.todoId
 * @param {number} args.queryParams
 */
export const removeTodo = ({ queryClient, projectId, todolistId, todoId, queryParams }) => {
  const {
    activeTodoIndex,
    activeQueryKey,
    completedQueryKey,
    bulkCompletedQueryKey,
    activeCache,
    completedCache,
    bulkCompletedCache,
    completedTodoIndex,
    completedTodoPageIndex,
    bulkCompletedTodoIndex,
  } = getTodoCache({ queryClient, projectId, todolistId, todoId, queryParams });

  if (activeCache && activeTodoIndex !== null) {
    activeCache.splice(activeTodoIndex, 1);
    queryClient.setQueryData(activeQueryKey, activeCache);
  }

  if (completedCache && completedTodoPageIndex !== null && completedTodoIndex !== null) {
    completedCache.pages[completedTodoPageIndex].data.splice(completedTodoIndex, 1);
    queryClient.setQueryData(completedQueryKey, completedCache);
  }

  if (bulkCompletedCache && bulkCompletedTodoIndex !== null) {
    if (!bulkCompletedCache.todos) {
      bulkCompletedCache.todos = [];
    }
    bulkCompletedCache.todos.splice(bulkCompletedTodoIndex, 1);
    queryClient.setQueryData(bulkCompletedQueryKey, bulkCompletedCache);
  }
};

/**
 * Move a todo within a todolist
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.queryKey
 * @param {number} args.todolistId
 * @param {number} args.startIndex
 * @param {number} args.endIndex
 */
export const moveInTodolist = ({ queryClient, queryKey, todolistId, startIndex, endIndex }) => {
  const cache = queryClient.getQueryData(queryKey);
  if (!cache) {
    return;
  }
  const todos = getTodolistTodosFromCache({ todolistId, cache, clone: true });
  const updatedCache = cache.filter((todo) => todo.todolistId !== todolistId);
  const todo = todos[startIndex];
  todos.splice(startIndex, 1);
  todos.splice(endIndex, 0, todo);
  updatedCache.push(...todos);
  queryClient.setQueryData(queryKey, updatedCache);
};

/**
 * Complete a todo (remove from active cache and add to completed cache)
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.projectId
 * @param {object} args.todolistId
 * @param {number} args.todoId
 * @param {number} args.queryParams
 */
export const completeTodo = ({ queryClient, projectId, todolistId, todoId, queryParams }) => {
  const {
    activeQueryKey,
    projectTableQueryKey,
    completedQueryKey,
    bulkCompletedQueryKey,
    activeCache,
    projectCache,
    completedCache,
    bulkCompletedCache,
    activeTodo,
    activeTodoIndex,
  } = getTodoCache({ queryClient, projectId, todolistId, todoId, queryParams });

  if (activeCache && activeTodoIndex !== null) {
    activeCache.splice(activeTodoIndex, 1);
    queryClient.setQueryData(activeQueryKey, activeCache);
  }

  if (completedCache && activeTodo) {
    completedCache.pages[0].data.unshift({ ...activeTodo, completedAt: DateTime.now().toISO() });
    queryClient.setQueryData(completedQueryKey, completedCache);
  }

  if (projectCache && todoId) {
    queryClient.setQueryData(
      projectTableQueryKey,
      projectCache.filter((todo) => todo.todoId !== todoId)
    );
  }

  if (bulkCompletedCache && activeTodo) {
    if (!bulkCompletedCache.todos) {
      bulkCompletedCache.todos = [];
    }
    bulkCompletedCache.todos.unshift({ ...activeTodo, completedAt: DateTime.now().toISO() });
    queryClient.setQueryData(bulkCompletedQueryKey, bulkCompletedCache);
  }
};

/**
 * Uncomplete a todo (remove from completed cache and add to active cache)
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.projectId
 * @param {object} args.todolistId
 * @param {number} args.todoId
 * @param {number} args.queryParams
 */
export const uncompleteTodo = ({ queryClient, projectId, todolistId, todoId, queryParams }) => {
  const {
    activeQueryKey,
    projectTableQueryKey,
    completedQueryKey,
    bulkCompletedQueryKey,
    activeCache,
    projectCache,
    completedCache,
    bulkCompletedCache,
    completedTodo,
    completedTodoIndex,
    completedTodoPageIndex,
    bulkCompletedTodo,
    bulkCompletedTodoIndex,
  } = getTodoCache({ queryClient, projectId, todolistId, todoId, queryParams });

  if (activeCache && (completedTodo || bulkCompletedTodo)) {
    activeCache.push({ ...(completedTodo ?? bulkCompletedTodo), completedAt: null });
    queryClient.setQueryData(activeQueryKey, activeCache);
  }

  if (completedCache && completedTodoPageIndex !== null && completedTodoIndex !== null) {
    completedCache.pages[completedTodoPageIndex].data.splice(completedTodoIndex, 1);
    queryClient.setQueryData(completedQueryKey, completedCache);
  }

  if (projectCache && (completedTodo || bulkCompletedTodo)) {
    projectCache.push({ ...(completedTodo ?? bulkCompletedTodo), completedAt: null });
    queryClient.setQueryData(projectTableQueryKey, projectCache);
  }

  if (bulkCompletedCache && bulkCompletedTodoIndex !== null) {
    if (!bulkCompletedCache.todos) {
      bulkCompletedCache.todos = [];
    }
    bulkCompletedCache.todos.splice(bulkCompletedTodoIndex, 1);
    queryClient.setQueryData(bulkCompletedQueryKey, bulkCompletedCache);
  }
};

/**
 * Remove a todolist
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.queryKey
 * @param {number} args.todolistId
 */
export const removeTodolist = ({ queryClient, queryKey, todolistId }) => {
  const cache = queryClient.getQueryData(queryKey);
  if (!Array.isArray(cache)) {
    // If cache is not an array, it's a single todolist - no need to optimistically update
    return;
  }

  const atIndex = cache.findIndex((tl) => tl.todolistId === todolistId);
  if (atIndex === -1) {
    return;
  }

  cache.splice(atIndex, 1);

  queryClient.setQueryData(queryKey, cache);
};

/**
 * Update todolist data
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.queryKey
 * @param {number} args.todolistId
 * @param {object} args.todolist
 */
export const replaceTodolist = ({ queryClient, queryKey, todolistId, todolist }) => {
  const cache = queryClient.getQueryData(queryKey);
  if (Array.isArray(cache)) {
    const replaceAt = todolistId ? cache.findIndex((tl) => tl.todolistId === todolistId) : 0;
    cache.splice(replaceAt, todolistId ? 1 : 0, todolist);
    queryClient.setQueryData(queryKey, cache);
  } else {
    queryClient.setQueryData(queryKey, todolist);
  }
};

/**
 * Reorder a todolist within a project
 * @param {object} args
 * @param {object} args.queryClient
 * @param {object} args.queryKey
 * @param {number} args.startIndex
 * @param {number} args.endIndex
 */
export const reorderTodolist = ({ queryClient, queryKey, startIndex, endIndex }) => {
  const cache = queryClient.getQueryData(queryKey);
  if (!Array.isArray(cache)) {
    return;
  }

  const todolist = cache[startIndex];
  cache.splice(startIndex, 1);
  cache.splice(endIndex, 0, todolist);

  queryClient.setQueryData(queryKey, cache);
};

/**
 * Get todolist from React Query cache
 * @param {object} args
 * @param {number} args.todolistId
 * @param {object} args.cache
 * @param {boolean} args.clone
 * @returns {Array} todolist and index in cache
 */
export const getTodolistFromCache = ({ todolistId, cache, clone = false }) => {
  if (!Array.isArray(cache)) {
    if (cache?.todolistId === todolistId) {
      return [clone ? cloneDeep(cache) : cache, null];
    }
    return [null, null];
  }
  const index = cache.findIndex((tl) => tl.todolistId === todolistId);
  const todolist = index !== -1 ? cache[index] : null;
  return [clone ? cloneDeep(todolist) : todolist, index];
};

/**
 * Get todolist todos from React Query cache
 * @param {object} args
 * @param {number} args.todolistId
 * @param {object} args.cache
 * @param {boolean} args.clone
 * @returns {Array} todos
 */
export const getTodolistTodosFromCache = ({ todolistId, cache, clone = false }) => {
  const todos = cache.filter((t) => t.todolistId === todolistId);
  return clone ? cloneDeep(todos) : todos;
};

/**
 * Get todo from React Query cache
 * @param {object} args
 * @param {number} args.todoId
 * @param {object} args.cache
 * @param {boolean} args.clone
 * @returns {Array} todo and its index in cache
 */
export const getTodoFromCache = ({ todoId, cache, clone = false }) => {
  if (!cache) {
    return [null, null];
  }

  const index = cache.findIndex((t) => t.todoId === todoId);
  const todo = index !== -1 ? cache[index] : null;

  if (!todo) {
    return [null, null];
  }

  return [clone ? cloneDeep(todo) : todo, index];
};

/**
 * Get completed todo from React Query cache
 * @param {object} args
 * @param {number} args.todoId
 * @param {object} args.cache
 * @param {boolean} args.clone
 * @returns {Array} todo, index of containing page, index of todo within page
 */
export const getCompletedTodoFromCache = ({ todoId, cache, clone = false }) => {
  if (!cache) {
    return [null, null, null];
  }

  let todo = null;
  let pageIndex = null;
  let index = null;
  // Cache is an infinite query
  if (cache.pages) {
    for (const [i, page] of cache.pages.entries()) {
      index = page.data.findIndex((t) => t.todoId === todoId);
      if (index !== -1) {
        todo = page.data[index];
        pageIndex = i;
      }
    }
  }

  if (!todo) {
    return [null, null, null];
  }

  return [clone ? cloneDeep(todo) : todo, pageIndex, index];
};

/**
 *
 * @param root0
 * @param root0.queryClient
 * @param root0.projectId
 * @param root0.todolistId
 * @param root0.todoId
 * @param root0.queryParams
 * @param root0.clone
 */
export const getTodoCache = ({
  queryClient,
  projectId,
  todolistId,
  todoId,
  queryParams,
  clone = false,
}) => {
  const activeQueryKey = todoQueryKeys.projectTodos({
    projectId,
    todolistId,
    queryParams,
  });
  const projectTableQueryKey = todoQueryKeys.allProjectTodos({
    projectId,
    todolistId,
    queryParams,
    includeCustomFields: true,
  });

  const completedQueryKey = todoQueryKeys.completedTodos({
    projectId,
    todolistId,
    queryParams,
  });
  const bulkCompletedQueryKey = todoQueryKeys.bulkCompletedTodos({
    projectId,
    todolistId,
    queryParams,
  });

  const activeCache = queryClient.getQueryData(activeQueryKey);
  const projectCache = queryClient.getQueryData(projectTableQueryKey);
  const completedCache = queryClient.getQueryData(completedQueryKey);
  const bulkCompletedCache = queryClient.getQueryData(bulkCompletedQueryKey);

  const [activeTodo, activeTodoIndex] = getTodoFromCache({ todoId, cache: activeCache, clone });
  const [completedTodo, completedTodoPageIndex, completedTodoIndex] = getCompletedTodoFromCache({
    todoId,
    cache: completedCache,
    clone,
  });
  const [bulkCompletedTodo, bulkCompletedTodoIndex] = getTodoFromCache({
    todoId,
    cache: bulkCompletedCache?.todos ?? [],
    clone,
  });

  return {
    activeCache,
    activeQueryKey,
    projectCache,
    projectTableQueryKey,
    activeTodo,
    activeTodoIndex,
    bulkCompletedCache,
    bulkCompletedQueryKey,
    bulkCompletedTodo,
    bulkCompletedTodoIndex,
    completedCache,
    completedQueryKey,
    completedTodo,
    completedTodoIndex,
    completedTodoPageIndex,
  };
};

/**
 *
 * @param filterValue
 * @param newFieldValue
 */
export const evaluateFilter = (filterValue, newFieldValue) => {
  const operator = Object.keys(filterValue)[0];
  let matchesFilter = false;
  switch (operator) {
    case '$eq':
      matchesFilter = newFieldValue === filterValue[operator];
      break;
    case '$ne':
      matchesFilter = newFieldValue !== filterValue[operator];
      break;
    case '$exists':
      matchesFilter = newFieldValue !== null;
      break;
    case '$notExists':
      matchesFilter = newFieldValue === null;
      break;
    case '$gt':
      matchesFilter = newFieldValue > filterValue[operator];
      break;
    case '$lt':
      matchesFilter = newFieldValue < filterValue[operator];
      break;
    default:
      matchesFilter = false;
  }

  return matchesFilter;
};
