import { store } from '@webfx/core-web';

store.dispatch.router.registerRoutes([
  // root
  { name: 'dashboard', url: '/dashboard' },
  { name: 'todos', url: '/todos' },
  { name: 'calendar', url: '/calendar' },
  { name: 'conversation', url: '/conversation' },
  { name: 'overview', url: '/overview' },
  { name: 'settings', url: '/settings' },
  { name: 'search', url: '/search' },
  { name: 'files', url: '/files' },
  { name: 'fileDownload', url: '/file-download/:uploadId' },
  { name: 'deletedProjects', url: '/deleted-projects' },
  { name: 'archivedProjects', url: '/archived-projects' },
  { name: 'customFields', url: '/custom-fields' },

  // projects
  { name: 'projectOverview', url: '/projects/:projectId/overview' },
  { name: 'projectMessagesArchive', url: '/projects/:projectId/posts-archive' },
  { name: 'projectCreateMessage', url: '/projects/:projectId/create-post' },
  { name: 'projectMessages', url: '/projects/:projectId/posts' },
  { name: 'projectFiles', url: '/projects/:projectId/files' },
  { name: 'projectFileUpload', url: '/projects/:projectId/file-upload' },
  { name: 'projectSettings', url: '/projects/:projectId/settings' },
  { name: 'projectMilestones', url: '/projects/:projectId/milestones' },
  { name: 'projectTodolists', url: '/projects/:projectId/todo-lists' },
  { name: 'projectListsPlus', url: '/projects/:projectId/lists-plus' },
  { name: 'projectTodoTable', url: '/projects/:projectId/todo-table' },
  { name: 'projectHistory', url: '/projects/:projectId/history/:page(timeline|contributors)?' },
  { name: 'projectDeletions', url: '/projects/:projectId/deletions' },
  { name: 'projectSearch', url: '/projects/:projectId/search' },
  { name: 'peopleAndPermissions', url: '/projects/:projectId/people-and-permissions' },
  { name: 'changePermissions', url: '/projects/:projectId/change-permission' },
  { name: 'collaboration', url: '/projects/:projectId/collaboration/:page?' },
  { name: 'billingPortal', url: '/projects/:projectId/billing-portal' },
  { name: 'project', url: '/projects/:projectId/:tab?' },

  // todolists
  { name: 'todolist', url: '/todo-lists/:todolistId?' },
  { name: 'todolistPlus', url: '/todo-lists-plus/:todolistId' },
  { name: 'todo', url: '/todos/:todoId' },

  // milestones
  { name: 'milestone', url: '/milestones/:milestoneId' },

  // messages
  { name: 'messageEdit', url: '/posts/:messageId/edit' },
  { name: 'messages', url: '/posts/:messageId?' },

  // templates
  { name: 'todosTemplate', url: '/templates/todos/:todolistId?' },
  { name: 'projectsTemplate', url: '/templates/projects' },
  { name: 'projectsTemplateTodosList', url: '/templates/projects/:templateId/todo-lists' },
  { name: 'projectsTemplateMilestones', url: '/templates/projects/:templateId/milestones' },
  { name: 'projectsTemplatePermissions', url: '/templates/projects/:templateId/permissions' },
  { name: 'todolistTemplate', url: '/templates/projects/:templateId/todo-lists/:todolistId' },
]);
